import React from 'react';
import {
    FormWithRedirect,
    TextInput,
    BooleanInput,
    useRecordContext,
} from 'react-admin';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { Toolbar, SaveButton } from 'react-admin';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        border: '1px solid #e0e0e0',
        borderRadius: '4px',
    },
    toolbarRoot: {
        padding: theme.spacing(2),
        border: '1px solid #e0e0e0',
        borderRadius: '4px',
    },
    section: {
        width: '100%',
        marginBottom: theme.spacing(3),
    },
    flex: {
        width: '100%',
        display: 'flex',
        gap: theme.spacing(2),
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    toolbar: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    saveButton: {
        maxWidth: '300px',
    },
}));

const VersionToolbar = (props: any) => {
    const classes = useStyles();
    return (
        <Toolbar {...props} className={classes.toolbarRoot}>
            <div className={classes.toolbar}>
                <SaveButton {...props} className={classes.saveButton} />
            </div>
        </Toolbar>
    );
};

const VersionEdit = (props: any) => {
    const classes = useStyles();
    const translate = useTranslate();
    const record = useRecordContext();

    if (!record) return null;

    return (
        <FormWithRedirect
            {...props}
            initialValues={record}
            render={({ handleSubmit, ...rest }) => (
                <form onSubmit={handleSubmit} className={classes.root}>
                    <div className={classes.section}>
                        <Typography variant="h6" gutterBottom>
                            {translate('resources.settings.groups.iosApp')}
                        </Typography>
                        <div className={classes.flex}>
                            <Box>
                                <Box>
                                    <TextInput
                                        source="ios.storeVersion"
                                        resource="settings"
                                        label="resources.settings.fields.storeVersion"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                                <Box>
                                    <TextInput
                                        source="ios.storeURL"
                                        resource="settings"
                                        label="resources.settings.fields.storeURL"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <Box>
                                    <BooleanInput
                                        resource="settings"
                                        source="ios.forceUpgrade"
                                        label="resources.settings.fields.forceUpgrade"
                                        helperText={false}
                                    />
                                </Box>
                                <Box>
                                    <BooleanInput
                                        resource="settings"
                                        source="ios.maintenanceMode"
                                        label="resources.settings.fields.maintenanceMode"
                                        helperText={false}
                                    />
                                </Box>
                            </Box>
                        </div>
                    </div>

                    <div className={classes.section}>
                        <Typography variant="h6" gutterBottom>
                            {translate('resources.settings.groups.androidApp')}
                        </Typography>
                        <div className={classes.flex}>
                            <Box>
                                <Box>
                                    <TextInput
                                        source="android.storeVersion"
                                        resource="settings"
                                        label="resources.settings.fields.storeVersion"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                                <Box>
                                    <TextInput
                                        source="android.storeURL"
                                        resource="settings"
                                        label="resources.settings.fields.storeURL"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <Box>
                                    <BooleanInput
                                        resource="settings"
                                        source="android.forceUpgrade"
                                        label="resources.settings.fields.forceUpgrade"
                                        helperText={false}
                                    />
                                </Box>
                                <Box>
                                    <BooleanInput
                                        resource="settings"
                                        source="android.maintenanceMode"
                                        label="resources.settings.fields.maintenanceMode"
                                        helperText={false}
                                    />
                                </Box>
                            </Box>
                        </div>
                    </div>
                    <VersionToolbar {...rest} />
                </form>
            )}
        />
    );
};

export default VersionEdit;
