import * as React from 'react';
import { FC } from 'react';
import {
    Edit,
    TextField,
} from 'react-admin';
import { Pc, FieldProps } from '../types';
import { TabbedForm } from 'react-admin';
import { FormTab } from 'react-admin';
import VersionEdit from './tabs/VersionsTab';

const SlideEdit = (props: any) => {
    return (
        <Edit
            title={<PcTitle />}
            {...props}
        >
            <SlideForm {...props} />
        </Edit>
    );
};

const PcTitle: FC<FieldProps<Pc>> = ({ record }) =>
    record ? <TextField source="title" record={record} size="32" /> : null;

const SlideForm = (props: any) => {

    return (
        <TabbedForm {...props} toolbar={false} >
            <FormTab {...props} label="resources.settings.tabs.versions">
                <VersionEdit {...props} />
            </FormTab>
        </TabbedForm>
    )
};

export default SlideEdit;
