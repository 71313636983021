import * as React from 'react';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import {
    useMediaQuery,
    Theme,
    createStyles,
    makeStyles,
} from '@material-ui/core';
import { useTranslate, MenuItemLink } from 'react-admin';

import users from '../users';
import admins from '../admins';
import settings from '../settings'
import { AppState } from '../types';
import { usePermissions } from 'react-admin';

interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        default: {
            paddingTop: 20,
        },
    })
);

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
    const translate = useTranslate();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change
    const styles = useStyles({});
    const { permissions } = usePermissions();
    return (
        <div className={styles.default}>
            <MenuItemLink
                to={`/users`}
                primaryText={translate(`resources.users.name`, {
                    smart_count: 2,
                })}
                leftIcon={<users.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            {permissions === 'admin' && (
                <>
                    <MenuItemLink
                        to={`/admins`}
                        primaryText={translate(`resources.admins.name`, {
                            smart_count: 2,
                        })}
                        leftIcon={<admins.icon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to={`/settings`}
                        primaryText={translate(`resources.settings.name`, {
                            smart_count: 2,
                        })}
                        leftIcon={<settings.icon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                </>
            )}

            {isXSmall && logout}
        </div>
    );
};

export default Menu;
