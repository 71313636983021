import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Datagrid, DateField, List } from 'react-admin';

const useStyles = makeStyles(theme => ({
    list: {
        margin: "10px 20px",
    },
    toolbar: {
        height: 0,
        minHeight: 0,
    },
}));

const EmptyToolbar = () => null;

const SlideList = (props: any) => {
    const classes = useStyles();
    return (
        <List
            {...props}
            filters={false}
            sort={{ field: 'title', order: 'ASC' }}
            perPage={10}
            exporter={false}
            bulkActionButtons={false}
            actions={null}
            className={classes.list}
            classes={{ toolbar: classes.toolbar }}
        >
            <Datagrid optimized rowClick="edit">
                <DateField
                    source="created_at"
                    type="date"
                    showTime
                    label="resources.settings.fields.created_at"
                />
                <DateField
                    source="updated_at"
                    type="date"
                    showTime
                    label="resources.settings.fields.updated_at"
                />
            </Datagrid>
        </List>
    );
};

export default SlideList;
