import SettingsIcon from '@material-ui/icons/Settings';

import List from './SettingsList';
import Edit from './SettingsEdit';

export default {
    list: List,
    edit: Edit,
    icon: SettingsIcon,
};
