import { TranslationMessages } from 'ra-core';
import frenchMessages from 'ra-language-french';

const customFrenchMessages: TranslationMessages = {
    ...frenchMessages,
    global: {
        auth: {
            email: 'Email',
            sign_in: 'Entrer',
            password: 'Mot de Passe',
            sign_in_error: "Erreur d'Authentification Failed",
        },
    },
    pos: {
        search: 'Rechercher',
        configuration: 'Configuration',
        language: 'Langue',
        theme: {
            name: 'Theme',
            light: 'Clair',
            dark: 'Obscur',
        },
        dashboard: {
            subscriptions_today: {
                title: 'Nouveaux Clients',
                subtitle: "Aujourd'hui",
            },
            subscriptions_total: {
                title: 'Total Clients',
                subtitle: '',
            },
            courses_today: {
                title: 'Nouvelles Courses',
                subtitle: "Aujourd'hui",
            },
            courses_total: {
                title: 'Total courses',
                subtitle: '',
            },
            drivers_online: {
                title: 'Chaufeurs Connectés',
                subtitle: 'Actuellement',
            },
            welcome: {
                title: 'Bienvenue dans ECG',
                subtitle: 'Ceci est le back-office ECG..',
                aor_button: 'Site web de ECG',
                demo_button: 'Code source de cette démo',
            },
        },
        menu: {
            sales: 'Ventes',
            prducts: 'Produits',
            users: 'Utilisateurs',
            delivery: 'Livraisons',
        },
        actions: {
            concel: 'Annuler',
        },
        deleteMessage: {
            ok: 'Élément supprimé avec succès',
            ko: 'Élément non supprimé',
        },
    },
    resources: {
        users: {
            name: 'Client |||| Clients',
            fields: {
                name: {
                    first: 'Nom',
                    last: 'Prénom',
                    full: 'Nom Complet',
                },
                email: 'Email',
                phone: 'Tél',
                civility: 'Genre',
                uid: 'Identifiant',
                address1: 'Adresse',
                city: 'Ville',
                country: 'Pays',
                birthdate: 'date de Naissance',
                socialReason: 'Raison Sociale',
                created_at: "Date d'inscription",
                updated_at: 'Dernière mise à jour',
                account_enabled: 'Activé',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe',
                num_adherent: 'N° d\'adherent',
            },
            civilities: {
                monsieur: 'M',
                madame: 'Mme',
            },
            filters: {
                last_visited: 'Dernière visite',
                today: "Aujourd'hui",
                this_week: 'Cette semaine',
                last_week: 'La semaine dernière',
                this_month: 'Ce mois-ci',
                last_month: 'Le mois dernier',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identité',
                phone: 'Tél',
                address: 'Adresse',
                pricing: 'Tarification',
                history: 'Historique',
                quizzes: 'Quizzes',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        admins: {
            name: 'Administrateur |||| Administrateurs',
            fields: {
                name: {
                    first: 'Nom',
                    last: 'Prénom',
                    full: 'Nom Complet',
                },
                email: 'Email',
                phone: 'Tél',
                address1: 'Adresse',
                city: 'Ville',
                country: 'Pays',
                socialReason: 'Raison Sociale',
                created_at: 'Crée le',
                updated_at: 'Dernière mise à jour',
                account_enabled: 'Activé',
                commands: 'Commandes',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe',
            },
            filters: {
                last_visited: 'Dernière visite',
                today: "Aujourd'hui",
                this_week: 'Cette semaine',
                last_week: 'La semaine dernière',
                this_month: 'Ce mois-ci',
                last_month: 'Le mois dernier',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identité',
                phone: 'Tél',
                address: 'Adresse',
                pricing: 'Tarification',
                history: 'Historique',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        settings: {
            name: 'Paramètres |||| Paramètres',
            fields: {
                storeVersion: 'Version de l\'app',
                storeURL: 'URL de l\'app',
                forceUpgrade: 'Forcer une mise à jour',
                maintenanceMode: 'Activer le mode maintenance',
                created_at: "Date de creation",
                updated_at: 'Dernière mise à jour',
            },
            groups: {
                androidApp: 'APP ANDROID',
                iosApp: 'APP IOS',
            },
            tabs: {
                versions: 'GESTION DES VERSIONS',
            }
        },
    },
};

export default customFrenchMessages;
