import * as React from 'react';
import { FC } from 'react';
import {
    Edit,
    TextInput,
    DateInput,
    PasswordInput,
    Toolbar,
    useTranslate,
    FormWithRedirect,
    required,
    email,
    BooleanInput,
} from 'react-admin';
import { Box, Card, CardContent, Typography } from '@material-ui/core';

import Aside from './Aside';
import FullNameField from './FullNameField';
import { validatePasswords } from './UserCreate';
import { Customer, FieldProps } from '../types';
import { SaveButton } from 'react-admin';
import { DeleteButton } from 'react-admin';

const UserEdit = (props: any) => {
    return (
        <Edit
            title={<UserTitle />}
            aside={<Aside />}
            undoable={false}
            component="div"
            {...props}
        >
            <UserForm />
        </Edit>
    );
};

const UserTitle: FC<FieldProps<Customer>> = ({ record }) =>
    record ? <FullNameField record={record} size="32" /> : null;

const UserForm = (props: any) => {
    const translate = useTranslate();

    return (
        <FormWithRedirect
            {...props}
            validate={validatePasswords}
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.users.fieldGroups.identity'
                                        )}
                                    </Typography>
                                    <TextInput
                                        source="name.first"
                                        resource="users"
                                        label="resources.users.fields.name.first"
                                        validate={requiredValidate}
                                        fullWidth
                                    />
                                    <TextInput
                                        source="name.last"
                                        resource="users"
                                        label="resources.users.fields.name.last"
                                        validate={requiredValidate}
                                        fullWidth
                                    />
                                    <TextInput
                                        type="email"
                                        source="email"
                                        resource="users"
                                        label="resources.users.fields.email"
                                        validate={[email(), required()]}
                                        fullWidth
                                    />
                                    <TextInput
                                        type="phone"
                                        source="phone"
                                        label="resources.users.fields.phone"
                                        resource="users"
                                        // validate={requiredValidate}
                                        fullWidth
                                    />
                                    <TextInput
                                        type="num_adherent"
                                        source="num_adherent"
                                        label="resources.users.fields.num_adherent"
                                        resource="users"
                                        fullWidth
                                    />
                                    <DateInput
                                        type="birthdate"
                                        source="birthdate"
                                        label="resources.users.fields.birthdate"
                                        resource="users"
                                        // validate={requiredValidate}
                                        fullWidth
                                    />
                                    <BooleanInput
                                        source="accountEnabled"
                                        label="resources.users.fields.account_enabled"
                                        fullWidth
                                    />

                                    <Box mt="1em" />

                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.users.fieldGroups.change_password'
                                        )}
                                    </Typography>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <PasswordInput
                                                source="password"
                                                resource="users"
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <PasswordInput
                                                source="confirm_password"
                                                resource="users"
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            undoable={false}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="users"
                        >
                            <div
                                style={{
                                    width: '100%',
                                    padding: '15px 0px',
                                    display: 'flex',
                                    gap: '20px',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <SaveButton {...formProps} />
                                <DeleteButton {...formProps} />
                            </div>
                        </Toolbar>
                    </form>
                </Card>
            )}
        />
    );
};

const requiredValidate = [required()];

export default UserEdit;
